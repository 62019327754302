<template>
    <div>
        <v-dialog v-model="dialog" max-width="1200px" persistent scrollable>
        <v-card>
          <v-card-title class="dialog_card_title">
            <span class="title font-weight-medium"> {{ title }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container grid-list-md>

                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 lg12>
                       <h3>Upravljalec zbirke osebnih podatkov:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Infrastruktura Bled, Rečiška cesta 2, Bled, 4260 Bled, tel: (04) 5780534, e-pošta: <a href="mailto:info@ibled.si">info@ibled.si</a></p>

                       <h3>Kontakti pooblaščene osebe za varstvo osebnih podatkov:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Jaka Bassanesse (<a href="mailto:jaka.jassanese@ibled.si">jaka.jassanese@ibled.si</a>) </p>

                       <h3>Osebni podatki, ki se obdelujejo:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Vlagatelj: ime in priimek / naziv, naslov, elektronska pošta, telefonska številka.</p>

                       <h3>Namen obdelave osebnih podatkov:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Izdaja izjave predkupnega upravičenca o sprejetju ali zavrnitvi ponudbe.</p>

                       <h3>Pravna podlaga v občini:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">41., 42. in 44. Člen Odloka o urejanju cestnega prometa v občini Bled (Uradno glasilo slovenskih občin, št. 14/2015, 31/2015, 13/2016, 56/2017, 29/2018, 28/2019, 54/2019)</p>

                       <h3>Uporabniki ali kategorije uporabnikov osebnih podatkov, če obstajajo:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Pogodbeni obdelovalec: Palisada sistemi d.o.o., Moste 2a, 4274 Žirovnica.</p>

                       <h3>Informacije o prenosih osebnih podatkov v tretjo državo ali mednarodno organizacijo:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Osebni podatki se NE prenašajo v tretje države ali mednarodne organizacije.</p>

                        <h3>Obdobje hrambe osebnih podatkov:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Skladno s klasifikacijskim načrtom občine.</p>

                       <h3>Informacije o obstoju pravic posameznika, da lahko zahteva dostop do osebnih podatkov in popravek ali izbris osebnih podatkov ali omejitev, ali obstoj pravice do ugovora obdelavi in pravice do prenosljivosti podatkov:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Posameznik ima glede osebnih podatkov, ki se nanašajo nanj/o, pravico do seznanitve z obdelavo OP, dopolnitve OP, popravka OP, omejitve obdelave OP, izbrisa OP, prenosljivosti OP in ugovora o obdelavi OP. Posameznik lahko pravice uveljavlja s posredovanjem vloge na naslov pooblaščene osebe za varstvo osebnih podatkov.</p>

                       <h3>Informacija o pravici do preklica privolitve, če je pravna podlaga za obdelavo OP privolitev/soglasje posameznika:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Posameznik lahko soglasje (privolitev) kadar koli prekliče, ne da bi to vplivalo na zakonitost obdelave podatkov, ki se je na podlagi privolitve izvajala do njenega preklica.</p>

                       <h3>Informacija o pravici do vložitve pritožbe pri nadzornem organu:</h3>
                       <div class="div__divider__5"></div> 
                       <p class="body-2 font-weight-regular card--text">Če želite v zvezi z uveljavljanjem svojih pravic s področja varstva osebnih podatkov podati pritožbo pri nadzornemu organu lahko le to posredujete na naslov: Informacijski pooblaščenec (naslov: Dunajska 22, 1000 Ljubljana, e-naslov: gp.ip@ip-rs.si telefon: 012309730, spletna stran: www.ip-rs.si).</p>
                       <p class="body-2 font-weight-regular card--text">Informacije o tem:</p>
                       <ul>
                           <li>ali je zagotovitev osebnih podatkov <strong>zakonska ali pogodbena obveznost: DA</strong></li>
                           <li>ali mora posameznik zagotoviti osebne podatke ter kakšne so morebitne posledice, če jih ne zagotovi: <strong>DA</strong> - Če zahtevani podatki ne bodo zagotovljeni, oddaja preko spletne strani ne bo možna.</li>
                       </ul>
                        
                    </v-flex>
                </v-layout>

            </v-container>
          </v-card-text>
        <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="close()" color="error" text><v-icon left>mdi-close</v-icon>Zapri</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>

export default {
    name: 'info-gdpr',

    data: () => ({
        title: 'Obvestilo posameznikom po 13. členu splošne uredbe o varstvu osebnih podatkov (GDPR) glede obdelave osebnih podatkov'
    }),

    computed: {
        dialog: {
            set(dialogState) {
                this.$store.commit('SET_INFO_GDPR', dialogState)
            }, 

            get() {
                return this.$store.state.infoGdpr
            }
        }
    },

    methods: {
        close() {
            this.$store.commit('SET_INFO_GDPR', false)
        }
    },

    mounted() {
    },

    destroyed() {

    }
}

</script>